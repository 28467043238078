import { Box, Typography } from "@mui/material"
import { observer } from "mobx-react"

import { ChatbotConfigurationConfigurableFormFields } from "src/modals/configuring-agents-detail/components/formField/configurableFormFields"
import { ChatbotConfiguringStore } from "src/modals/configuring-agents-detail/store"
import { useStore } from "src/store/lib/useStore"

export const ChatbotConfigurationSection = observer(() => {
    const store = useStore(ChatbotConfiguringStore)
    const configurableFields = store.form.get("configurable_fields")

    return (
        <Box>
            {configurableFields !== undefined &&
                configurableFields.map((field) => (
                    <Box sx={{ mb: 3 }}>
                        {field.type === "file" && (
                            <Typography variant="subtitle2" gutterBottom>
                                {field.name}
                            </Typography>
                        )}
                        <Typography variant="body1" gutterBottom mb={2}>
                            {field.description}
                        </Typography>
                        <ChatbotConfigurationConfigurableFormFields
                            field={field}
                        />
                    </Box>
                ))}
        </Box>
    )
})
