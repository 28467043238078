import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useState } from "react"
import { Button } from "@mui/material"

import { ModalHeader } from "src/components/ModalHeader"
import { ChatbotConfiguringStore } from "src/modals/configuring-agents-detail/store"
import { GlobalStore } from "src/store"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { useInitializer } from "src/lib/initializer"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"

import { PublishingSection } from "src/modals/configuring-agents-detail/sections/PublishingSection"

import { ChatbotConfigurationSection } from "src/modals/configuring-agents-detail/sections/ChatbotConfigurationSection"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { MetaDataSection } from "src/modals/configuring-agents-detail/sections/MetaDataSection"

export interface IChatbotConfigurationModalViewProps {
    id?: string
}

const View = observer(({ id }: IChatbotConfigurationModalViewProps) => {
    const store = useStore(ChatbotConfiguringStore)
    const globalStore = useStore(GlobalStore)

    const initialized = useInitializer(async () => {
        await store.init(id, globalStore.session.accessGroupId)
    }, [id, store])

    const [thisModal] = useState(
        globalStore.modals.active[globalStore.modals.active.length - 1],
    )

    thisModal.confirmOnOverlayClick = () => {
        return store.form.getIsDirty()
    }

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            store.setIsLoading(true)
            const validationError = await store.handleSubmit()

            if (validationError && !Boolean(store.form.error("generic"))) {
                globalStore.modals.pop()
            }
        },
        [globalStore.modals, store],
    )

    if (!initialized) {
        globalStore.loading.is(ChatbotConfiguringStore.LoadingKeys.init)
        return <CenteredSpinner height="100vh" />
    }

    const isSaveButtonDisabled =
        store.form.getIsDirty() === false || store.isLoading || store.isReadOnly

    return (
        <Form onSubmit={handleSubmit}>
            <ModalHeader>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={isSaveButtonDisabled}
                >
                    {t`global.save`}
                </Button>
            </ModalHeader>
            <FormPanel
                sections={[
                    {
                        header: t`chatbot-configuration-detail.meta-data`,
                        content: <MetaDataSection />,
                    },
                ]}
            />
            {store.form.get("config_type") !== "" && (
                <FormPanel
                    sections={[
                        {
                            header: t`chatbot-configuration-detail.chatbot-configuration-settings`,
                            content: <ChatbotConfigurationSection />,
                        },
                    ]}
                />
            )}
            <FormPanel
                sections={[
                    {
                        header: t`chatbot-configuration-detail.publishing-settings`,
                        content: <PublishingSection />,
                    },
                ]}
            />
        </Form>
    )
})

export const ChatbotConfigurationModalView = (
    props: IChatbotConfigurationModalViewProps,
) => (
    <StoreProvider Store={ChatbotConfiguringStore}>
        <View {...props} />
    </StoreProvider>
)
