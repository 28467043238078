import { observer } from "mobx-react"

import { config_Field } from "src/api"
import ImageSelectionSection from "src/components/AssetAttachmentsField"
import { ModalTextField } from "src/components/ModalTextField"
import { ChatbotConfiguringStore } from "src/modals/configuring-agents-detail/store"
import { useStore } from "src/store/lib/useStore"

export const ChatbotConfigurationConfigurableFormFields = observer(
    ({ field }: { field: config_Field }) => {
        const store = useStore(ChatbotConfiguringStore)
        const fieldName =
            field.localised === true ? `${field.name}_en` : field.name ?? ""

        switch (field.type) {
            case "text":
                return (
                    <ModalTextField
                        variant="withToolTip"
                        label={field.label}
                        value={store.form.get(fieldName) as string | number}
                        onChange={store.form.setter(fieldName)}
                        helperText={store.form.error(fieldName)}
                        error={Boolean(store.form.error(fieldName))}
                        disabled={store.isReadOnly}
                        tooltipText={field.tooltip_key ?? ""}
                        rows={1}
                    />
                )
            case "file":
                return (
                    <ImageSelectionSection
                        imageUrl={store.form.get(fieldName) as string}
                        onImageDelete={() => store.form.set(fieldName, "")}
                        onImageChange={(images) =>
                            store.handleIconChange(images, fieldName)
                        }
                        errorMessage={store.form.error(fieldName) ?? ""}
                        setErrorMessage={(error) =>
                            store.form.setError(fieldName, error)
                        }
                        acceptedFormats={["image/png", "image/jpeg"]}
                        dimensions={{ width: 150, height: 150 }}
                        disabled={store.isReadOnly}
                    />
                )
            default:
                // dropdown type is return by API, but currently not working, so we are not handling it
                return <></>
        }
    },
)
