import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"

import React from "react"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import {
    avy_api_pkg_segment_SegmentType,
    ChatbotAdminService,
    config_CreateFeatureConfigRequest,
    config_FeatureConfigJson,
    config_Field,
    config_GetFeatureConfigResponse,
    config_UpdateFeatureConfigRequest,
} from "src/api"
import { loads } from "src/channel/utils"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { reportError } from "src/lib/report"

import { Channel } from "src/channel"
import { persistFile } from "src/lib/file"
import {
    IChatbotConfigurationConfigurableAgentsList,
    IChatbotConfigurationConfigurableFormFields,
    IChatbotConfigurationItemProps,
} from "src/modals/configuring-agents-detail/types/configAgentsItemProps"

export class ChatbotConfiguringStore {
    //#region initializers
    static Context = React.createContext<ChatbotConfiguringStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    hasError: boolean = false
    isLoading = false

    private _configurableAgentsLists: IChatbotConfigurationConfigurableAgentsList[] =
        []
    private _publishingLevel?: avy_api_pkg_segment_SegmentType = undefined
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private _accessType: string = "WRITE"

    form = new FormFields<
        IChatbotConfigurationItemProps &
            IChatbotConfigurationConfigurableFormFields
    >({
        access_group_id: DEFAULT_ACCESS_GROUP.id,
        access_type: "NONE",
        feature_config_id: undefined,
        config_type: "",
        publishing_level: undefined,
        name: "",
        segment_ids: [],
        configurable_fields: [],
        config: {},
    })

    constructor() {
        makeAutoObservable(this)
    }
    //#endregion

    //#region getters
    get isReadOnly() {
        return this._accessType === "READ"
    }

    get configurableAgentsLists() {
        return this._configurableAgentsLists
    }

    get accessGroupId() {
        return this._accessGroupId
    }

    get publishingLevel() {
        return this._publishingLevel
    }
    //#endregion

    //#region setters
    private setAccessType(accessType: string) {
        this._accessType = accessType
    }

    private setAccessGroupId(id: number) {
        this._accessGroupId = id
    }

    private setPublishingLevel(level?: avy_api_pkg_segment_SegmentType) {
        this._publishingLevel = level
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)
    //#endregion

    //#region helpers
    private getDynamicConfigurableFormFields(
        config: config_FeatureConfigJson,
        configurable_fields: config_Field[],
    ) {
        const fields: IChatbotConfigurationConfigurableFormFields = {}

        configurable_fields.forEach((field) => {
            const fieldName = field.name as string
            const isLocalizedField = field.localised ?? false
            const configField = config[fieldName]

            if (isLocalizedField) {
                Object.keys(configField).forEach((key) => {
                    fields[`${fieldName}_${key}`] = configField[key]
                })
                return
            }
            fields[fieldName] = configField
        })

        return fields
    }

    private getConfigAPIPayload() {
        const configurableFields = this.form.get("configurable_fields")
        const config: config_FeatureConfigJson = {}

        if (configurableFields === undefined) return

        configurableFields.forEach((field) => {
            const fieldName = field.name as string
            const isLocalizedField = field.localised ?? false

            isLocalizedField === true
                ? (config[fieldName] = {
                      en: this.form.get(`${fieldName}_en`),
                  })
                : (config[fieldName] = this.form.get(fieldName))
        })

        return config
    }

    async handleSubmit() {
        this.form.validateRequiredFields([{ field: "name" }])

        if (this.form.hasErrors() === true) {
            this.setIsLoading(false)
            return false
        }

        trackModuleEvent("Chatbot Agent Items | Save", {
            [MixpanelProperties.ItemName]: this.form.data.agent_name,
            [MixpanelProperties.AccessGroupID]: this.form.data.access_group_id,
        })
        await this.submit()
        return true
    }

    handleIconChange = async (images: IFile[], fieldName: string) => {
        const [icon] = images
        if (icon === undefined || Object.keys(icon).length === 0) return

        const image = await persistFile(icon as ILocalFile, "image")

        if (image.url !== undefined) {
            this.form.set(fieldName, image.url)
        }
    }

    handleConfigTypeChange = (value: string) => {
        this.form.set("config_type", value)
        const configurableFields = this.configurableAgentsLists.find(
            (field) => field.config_type === value,
        )
        this.form.set(
            "configurable_fields",
            configurableFields?.configurable_fields ?? [],
        )
        this.setPublishingLevel(configurableFields?.publishing_level)
    }
    //#endregion

    //#region store operations
    async getConfigurableAgentsList() {
        let responseData = []
        const response =
            await ChatbotAdminService.getV1AdminChatbotAgentConfigConfigurableAgents()
        responseData = response?.configurable_features ?? []

        this._configurableAgentsLists =
            responseData?.map((val) => {
                return {
                    id: val.config_type ?? "",
                    label: val.config_type ?? "",
                    configurable_fields: val.configurable_fields ?? [],
                    publishing_level: val.publishing_level,
                    config_type: val.config_type ?? "",
                }
            }) ?? []
    }

    @loads(() => ChatbotConfiguringStore.LoadingKeys.init)
    async init(id: string | undefined, accessGroupId: number) {
        try {
            await this.getConfigurableAgentsList()
            this.setAccessGroupId(accessGroupId)

            if (id !== undefined) {
                const response =
                    await ChatbotAdminService.getV1AdminChatbotAgentConfig({
                        configId: id,
                    })

                this.form.init({
                    access_group_id:
                        response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                    access_type: response?.access_type ?? "NONE",
                    feature_config_id: response?.feature_config_id ?? -1,
                    config_type: response?.config_type ?? "",
                    configurable_fields: response?.configurable_fields ?? [],
                    config: response?.config ?? {},
                    created_at: response?.created_at ?? "",
                    updated_at: response?.updated_at ?? "",
                    name: response?.name ?? "",
                    segment_ids: response?.segment_ids ?? [],
                    ...this.getDynamicConfigurableFormFields(
                        response?.config ?? {},
                        response.configurable_fields ?? [],
                    ),
                })

                this.setAccessGroupId(
                    response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                )
                if (response?.access_type !== undefined) {
                    this.setAccessType(response?.access_type)
                }
            }
        } catch (error) {
            reportError(t`chatbot-configuration-detail.load-data-fail`, error)
            this.setAccessType("READ")
        }

        return Promise.resolve()
    }

    @loads(() => ChatbotConfiguringStore.LoadingKeys.submit)
    async submit() {
        try {
            await this.form.catchErrors(async () => {
                const isUpdate =
                    this.form.get("feature_config_id") !== undefined
                let configId: string | number = isUpdate
                    ? `${this.form.get("feature_config_id")}`
                    : ""
                let response: config_GetFeatureConfigResponse = {}
                const commonConfig:
                    | config_UpdateFeatureConfigRequest
                    | config_CreateFeatureConfigRequest = {
                    name: this.form.get("name"),
                    config_type: this.form.get("config_type"),
                    config: this.getConfigAPIPayload(),
                }

                if (isUpdate) {
                    await ChatbotAdminService.putV1AdminChatbotAgentConfig({
                        configId,
                        request: {
                            ...commonConfig,
                            feature_config_id:
                                this.form.get("feature_config_id"),
                            access_group_id: this.form.get("access_group_id"),
                        },
                    })
                } else {
                    response =
                        await ChatbotAdminService.postV1AdminChatbotAgentConfig(
                            {
                                request: {
                                    ...commonConfig,
                                    access_group_id: this.accessGroupId,
                                },
                            },
                        )
                    configId =
                        response.feature_config_id !== undefined
                            ? `${response.feature_config_id}`
                            : ""
                }

                if (configId !== "") {
                    await ChatbotAdminService.putV1AdminChatbotAgentConfigPublish(
                        {
                            configId,
                            request: {
                                published_in: this.form.get("segment_ids"),
                            },
                        },
                    )
                }

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "chatbot-configuration",
                        action: isUpdate ? "update" : "create",
                    },
                })
            })
        } catch (error) {
            reportError(t`chatbot-configuration-detail.save-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }
    //#endregion
}
