import { t } from "@lingui/macro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo } from "react"
import { Tooltip, Typography } from "@mui/material"

import { RowCellContainer } from "./styled"
import { ChatbotConfigurationStore } from "./store"

import { DataGridProTable } from "src/components/Table/DataGridPro"
import { useStore } from "src/store/lib/useStore"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { Repository } from "src/types/channel"
import { GlobalStore } from "src/store"

import { ListPage } from "src/components/ListPage"
import { IColumn } from "src/types/data-grid-pro"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { IAgentConfigItem } from "src/views/configuring-agents-persona/types"

import { ConfirmModal } from "src/modals/confirm"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { SegmentPickerButton } from "src/components/SegmentPickerButton"

import { ChatbotConfigurationModalView } from "src/modals/configuring-agents-detail"
import { TooltipContainer } from "src/styledComponents/TooltipContainer"

const REPOSITORY: Repository = "chatbot-configuration"

const View = observer(() => {
    const store = useStore(ChatbotConfigurationStore)
    const globalStore = useStore(GlobalStore)

    useEffect(() => {
        ;(async () => await store.init(globalStore.session.accessGroupId))()

        return () => store.dispose()
    }, [store, globalStore.session.accessGroupId])

    const columns: IColumn<IAgentConfigItem>[] = [
        {
            field: "name",
            headerName: t`chatbot-configuration-view.column.internal-name`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            minWidth: 120,
        },
        {
            field: "config_type",
            headerName: t`chatbot-configuration-view.column.agent-type`,
            renderCell: (params) => (
                <RowCellContainer>{params.value}</RowCellContainer>
            ),
            type: "string",
            minWidth: 120,
        },
        {
            field: "segment_names",
            headerName: t`chatbot-configuration-view.column.visible-for`,
            renderCell: (params) => {
                return (
                    <TooltipContainer sx={{ cursor: "pointer" }}>
                        <Tooltip
                            arrow
                            title={params?.row?.segment_names?.join(", ") ?? ""}
                        >
                            <Typography>
                                {`${
                                    params.value?.length
                                } ${t`chatbot-configuration-view.segments`.toLowerCase()}`}
                            </Typography>
                        </Tooltip>
                    </TooltipContainer>
                )
            },
            type: "string",
            minWidth: 178,
        },

        {
            field: "access_type",
            headerName: t`chatbot-configuration-view.column.access`,
            renderCell: (params) => (
                <AccessTypeHeader accessType={params.value} />
            ),
            type: "string",
            filterable: false,
            sortable: false,
            flex: 1,
        },
    ]

    const handleNewConfiguringItemClick = useCallback(() => {
        globalStore.modals.open(() => <ChatbotConfigurationModalView />)
    }, [globalStore.modals])

    const header = useMemo(
        () => ({
            header: t`Agents`,
            breadcrumbs: [
                t`chatbot-configuration-view.header.ai-chatbots-Agents`,
            ],
            createOptions: {
                onClick: handleNewConfiguringItemClick,
                item: t`Agents`,
            },
        }),
        [handleNewConfiguringItemClick],
    )

    useEffect(() => {
        return () => store.dispose()
    }, [store, globalStore.session.accessGroupId])

    const openDetailModalHandler = useCallback(
        (item) => {
            trackModuleEvent("Agents Items | Detailed View", {
                [MixpanelProperties.ItemID]: item.id,
                [MixpanelProperties.AccessGroupName]:
                    globalStore.session.accessGroup?.name,
                [MixpanelProperties.AccessGroupID]:
                    globalStore.session.accessGroup?.id,
            })
            globalStore.modals.open(() => (
                <ChatbotConfigurationModalView id={item.id} />
            ))
        },
        [
            globalStore.modals,
            globalStore.session.accessGroup?.id,
            globalStore.session.accessGroup?.name,
        ],
    )

    const handleDeleteItem = useCallback(
        (id) => {
            globalStore.modals.open(
                () => (
                    <ConfirmModal
                        onConfirm={async (confirmed) => {
                            if (confirmed) {
                                await store.deleteItem(id)
                            }
                        }}
                        title={t`chatbot-configuration-view.delete.are-you-sure`}
                        content={t`chatbot-configuration-view.delete.do-you-want-delete`}
                    />
                ),
                {
                    variant: "slide-up-w600",
                },
            )
        },
        [globalStore.modals, store],
    )

    const handleSegmentChange = useCallback(
        (segments: number[]) => store.loadSegments(segments),
        [store],
    )

    const filter = useMemo(
        () => ({
            actions: (
                <>
                    <SegmentPickerButton
                        value={store.segments}
                        onChange={handleSegmentChange}
                    />
                </>
            ),
        }),
        [handleSegmentChange, store.segments],
    )

    return (
        <ListPage
            header={header}
            filter={filter}
            loading={!store.chatbotConfigurationItems.meta.initialized}
        >
            <DataGridProTable
                paginator={store.chatbotConfigurationItems}
                data={store.chatbotConfigurationItems.items}
                columns={columns}
                advancedOperations={{
                    pagination: "server",
                    sorting: "server",
                }}
                rowActionsRenderer={(item) => [
                    {
                        text: t`chatbot-configuration-view.action.edit-agent`,
                        onClick: () => openDetailModalHandler(item),
                    },
                    {
                        text: t`chatbot-configuration-view.action.delete-item`,
                        destructive: true,
                        onClick: () => handleDeleteItem(item.id as number),
                    },
                ]}
                onRowClickEvent={openDetailModalHandler}
                repository={REPOSITORY}
                hideToolbar={true}
                loading={!store.chatbotConfigurationItems.meta.initialized}
            />
        </ListPage>
    )
})

export const ChatbotConfigurationView = () => (
    <StoreProvider Store={ChatbotConfigurationStore}>
        <View />
    </StoreProvider>
)
