/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "@lingui/macro"
import { Button, Stack, IconButton } from "@mui/material"
import { observer } from "mobx-react"
import { useCallback } from "react"

import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined"
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"

import { ChatbotDetailStore } from "./store"

import { ConversationDetail } from "./form-fields/ConversationDetails"

import { Feedback } from "./form-fields/Feedback"
import { Transcript } from "./form-fields/Transcript"

import { Form } from "src/components/Form"
import { FormPanel } from "src/components/FormPanel"
import { ModalHeader } from "src/components/ModalHeader"
import { StoreProvider } from "src/store/lib/StoreProvider"
import { useStore } from "src/store/lib/useStore"
import { GlobalStore } from "src/store"
import { SimulatedStatusChip } from "src/views/chatbot/components/SimulatedStatusChip"
import { TagsComponent } from "src/components/Tags"
import { ITag } from "src/types/tags/tag"
import { CenteredSpinner } from "src/components/CenteredSpinner"
import { IChatbotConversationDetailProps } from "src/modals/chatbot/types/chatbotConversationsDetailsProps"
import { getTagIds } from "src/modals/chatbot/helpers/getTagIdsFromTag"
import { chatbot_Tags } from "src/api"
import { useInitializer } from "src/lib/initializer"

const ChatbotConversationDetail = observer(
    (props: IChatbotConversationDetailProps) => {
        const { conversationId, allConversationIds } = props
        const store = useStore(ChatbotDetailStore)
        const globalStore = useStore(GlobalStore)

        const initialized = useInitializer(() => {
            store.setNavigationData(allConversationIds, conversationId)
            return store.init(conversationId)
        }, [conversationId])

        const handleNext = useCallback(async () => {
            await store.navigateToConversation("next")
        }, [store])

        const handlePrevious = useCallback(async () => {
            await store.navigateToConversation("previous")
        }, [store])

        const handleSubmit = useCallback(
            async (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault()
                await store.handleSubmit(conversationId)

                if (!Boolean(store.formFields.error("generic"))) {
                    globalStore.modals.pop()
                }
            },
            [],
        )

        const renderHeaderStatusChip = useCallback(
            () => (
                <SimulatedStatusChip
                    labelSimulated={t`chatbot-view.list.user-simulated`}
                    labelNotSimulated={t`chatbot-view.list.user-not-simulated`}
                    value={store.formFields.get("impersonated_by")}
                />
            ),
            [],
        )

        const handleTagChange = useCallback(
            (value: ITag[]) => {
                const tags: chatbot_Tags = value.reduce(
                    (acc, currVal) => ({
                        ...acc,
                        [currVal.id]: currVal.name,
                    }),
                    {},
                )

                store.formFields.set("tags", tags)
            },
            [conversationId],
        )

        const isButtonDisabled =
            globalStore.loading.is(ChatbotDetailStore.LoadingKeys.init) ||
            globalStore.loading.is(ChatbotDetailStore.LoadingKeys.loading) ||
            store.formFields.getIsDirty() === false

        if (
            globalStore.loading.is(ChatbotDetailStore.LoadingKeys.init) ||
            !initialized
        ) {
            return <CenteredSpinner />
        }
        return (
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isButtonDisabled}
                    >
                        {t`global.save`}
                    </Button>
                </ModalHeader>

                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent={"space-between"}
                >
                    <IconButton
                        aria-label="Preview"
                        disabled={!isButtonDisabled || !store.goPrevious()}
                        title={t`chatbot-view.modal.preview-conversation`}
                        onClick={handlePrevious}
                    >
                        <ArrowBackOutlinedIcon />
                    </IconButton>
                    <IconButton
                        aria-label="Next"
                        disabled={!isButtonDisabled || !store.goNext()}
                        title={t`chatbot-view.modal.next-conversation`}
                        onClick={handleNext}
                    >
                        <ArrowForwardOutlinedIcon />
                    </IconButton>
                </Stack>

                <FormPanel
                    sections={[
                        {
                            header: t`chatbot-view.modal.conversation-details`,
                            headerStatusChip: renderHeaderStatusChip(),
                            content: (
                                <ConversationDetail
                                    conversationID={store.currentConversationId}
                                    tenantID={
                                        store.formFields.get("tenant_id") ?? 0
                                    }
                                    endDate={
                                        store.formFields.get("ended_at") ?? ""
                                    }
                                    resolution={
                                        store.formFields.get("resolution") ?? ""
                                    }
                                />
                            ),
                        },
                    ]}
                />
                <FormPanel
                    sections={[
                        {
                            header: t`chatbot-view.modal.tags-section.header`,
                            content: (
                                <TagsComponent
                                    id={store.currentConversationId}
                                    tagIds={getTagIds(
                                        store.formFields.get("tags"),
                                    )}
                                    onChange={handleTagChange}
                                />
                            ),
                        },
                    ]}
                />

                <FormPanel
                    sections={[
                        {
                            header: t`chatbot-view.modal.feedback-section.header`,
                            content: (
                                <Feedback
                                    feedback={
                                        store.formFields.get(
                                            "tenant_feedback_reason",
                                        ) ?? ""
                                    }
                                    userThumbStatus={
                                        store.formFields.get(
                                            "tenant_feedback_thumb_status",
                                        ) ?? -1
                                    }
                                    adminThumbStatus={
                                        store.formFields.get(
                                            "admin_feedback_thumb_status",
                                        ) ?? undefined
                                    }
                                    adminReason={
                                        store.formFields.get(
                                            "admin_feedback_reason",
                                        ) ?? ""
                                    }
                                />
                            ),
                        },
                    ]}
                />
                <FormPanel
                    sections={[
                        {
                            header: t`chatbot-view.modal.transcript-section.header`,
                            rightComponent: (
                                <Button
                                    sx={{ ml: 1 }}
                                    onClick={() =>
                                        store.loadTranslationMessages(
                                            store.currentConversationId,
                                            globalStore.session.language,
                                        )
                                    }
                                    color="info"
                                >
                                    {store.isTranslationEnabled
                                        ? t`chatbot-view.modal.transcript-section.hide-translation`
                                        : t`chatbot-view.modal.transcript-section.translate`}
                                </Button>
                            ),
                            content: (
                                <Transcript
                                    messages={store.messages}
                                    isTranslationEnabled={
                                        store.isTranslationEnabled
                                    }
                                />
                            ),
                        },
                    ]}
                />
            </Form>
        )
    },
)

export const ChatbotConversationModalView = (
    props: IChatbotConversationDetailProps,
) => (
    <StoreProvider Store={ChatbotDetailStore}>
        <ChatbotConversationDetail
            conversationId={props.conversationId}
            allConversationIds={props.allConversationIds}
        />
    </StoreProvider>
)
